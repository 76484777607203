import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Cast Iron Strength
			</title>
			<meta name={"description"} content={"Energize Your Life!"} />
			<meta property={"og:title"} content={"Cast Iron Strength"} />
			<meta property={"og:description"} content={"Energize Your Life!"} />
			<meta property={"og:image"} content={"https://crysanta.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://crysanta.com/images/icon.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://crysanta.com/images/icon.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://crysanta.com/images/icon.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://crysanta.com/images/icon.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://crysanta.com/images/icon.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://crysanta.com/images/icon.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section padding="120px 0 180px 0" color="--darkD2" quarkly-title="Contacts-8">
			<Override slot="SectionContent" flex-direction="row" align-items="flex-start" lg-flex-direction="column" />
			<Box
				min-width="100px"
				min-height="100px"
				width="40%"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				justify-content="center"
				lg-margin="0px 0 30px 0px"
				align-items="flex-start"
				sm-margin="0px 0px 0 0px"
				lg-padding="0px 180px 0px 0px"
				sm-padding="0px 0 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 20px 0px" font="normal 600 38px/1.2 --fontFamily-sansHelvetica" color="--dark">
				Get In Touch
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
					color="--darkD2"
					lg-margin="0px 0px 20px 0px"
					md-width="100%"
				>Dive deeper into the extensive offerings at Cast Iron Strength by visiting us or reaching out for more detailed information. Our team is dedicated to providing you with all the tools and guidance you need to succeed in your fitness journey.
<br/><br/>
				Visit or Contact Cast Iron Strength – Start Your Transformation Today!

				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0 0 10%"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="36px 34px"
				md-grid-template-columns="1fr"
				flex-direction="column"
				width="50%"
				lg-margin="0px 0 0 0"
				lg-width="100%"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-darkD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Text
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							10 Swanfield, Edinburgh EH6 5RX, UK
						</Text>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-darkD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="tel:+44 7917 808227"
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							+44 7917 808227
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-darkD2"
					border-radius="4px"
					align-items="center"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="mailto:contact@crysanta.com"
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							contact@crysanta.com
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});